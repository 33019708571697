<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
}
</style>
