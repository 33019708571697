<template>
  <div id="page" ref="page" class="page">
    <van-row id="top">
      <van-col span="24">
        <van-nav-bar title="电子发票" left-arrow @click-left="back()" />
      </van-col>
    </van-row>
    <van-row class="middle">
      <van-col span="24">
        <van-row class="subtitle">
          <van-col span="24">开票信息</van-col>
        </van-row>
        <van-row class="card">
          <van-col span="24">
            <van-row class="info">
              <van-col span="2" class="icon"
                ><van-icon name="user-o"
              /></van-col>
              <van-col span="4" class="title">用户名称:</van-col>
              <van-col span="18" class="content">{{ house.name }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="phone-o" /></van-col>
              <van-col span="4">手机号码:</van-col>
              <van-col span="18">{{ house.phone }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="location-o" /></van-col>
              <van-col span="4">用户地址:</van-col>
              <van-col span="18">{{ house.address }}</van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row class="subtitle">
          <van-col span="24">费用信息</van-col>
        </van-row>
        <van-row class="card">
          <van-col span="24">
            <van-row class="info">
              <van-col span="2" class="icon"
                ><van-icon name="clock-o"
              /></van-col>
              <van-col span="4" class="title">采暖年份:</van-col>
              <van-col span="18" class="content">{{ invoice.years }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="ecard-pay" /></van-col>
              <van-col span="4">计费面积:</van-col>
              <van-col span="18">{{ invoice.area }}㎡</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="cash-back-record" /></van-col>
              <van-col span="4">折扣金额:</van-col>
              <van-col span="18">{{ invoice.discount }}￥</van-col>
            </van-row>
            <van-row class="submit">
              <van-col span="16" class="money"
                >开票金额：￥{{ invoice.money }}元</van-col
              >
              <van-col span="8"
                ><van-button
                  color="linear-gradient(to right, #ff6034, #ee0a24)"
                  size="mini"
                  @click="createInvoice()"
                >
                  开具电子发票
                </van-button></van-col
              >
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-tabbar v-model="active" active-color="#07c160" inactive-color="#000">
      <van-tabbar-item icon="home-o" @click="home()">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o" @click="bind()"
        >用户绑定</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data () {
    return {
      houseCode: '',
      receivedCode: '',
      house: {},
      invoice: {}
    }
  },
  mounted () {
    var param = this.$route.query
    this.houseCode = param.houseCode
    this.receivedCode = param.receivedCode
    this.retrieveHouse()
    this.retrieveInvoice()
  },
  methods: {
    async retrieveHouse () {
      var pd = { houseCode: this.houseCode }
      const { data: res } = await this.$http.post('/business/house/retrieveHouse', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.house = res.data
      }
    },
    async retrieveInvoice () {
      var pd = { receivedCode: this.receivedCode }
      const { data: res } = await this.$http.post('/business/invoice/retrieveInvoice', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.invoice = res.data
      }
    },
    async createInvoice () {
      var pd = {
        receivedCode: this.receivedCode,
        name: this.house.name,
        phone: this.house.phone,
        address: this.house.address,
        money: this.invoice.money,
        area: this.invoice.area,
        discount: this.invoice.discount
      }
      const { data: res } = await this.$http.post('/business/invoice/createInvoice', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.push('/')
      }
    },
    home () {
      this.$router.push('/')
    },
    bind () {
      this.$router.push('/bind')
    },
    back () {
      window.history.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  background-color: #fafafa;
}
.middle {
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.middle .subtitle {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 10px 0px 30px;
}
.middle .card {
  margin: 8px 10px 10px 10px;
  border-radius: 6px;
  background-color: #fff;
}
.middle .card .info {
  height: 20px;
  line-height: 20px;
  border-bottom: 1px solid #eee;
  padding: 5px;
  margin: 5px;
}
.middle .card .icon {
  font-size: 15px;
}
.middle .card .title {
  text-align: left;
}
.middle .content {
  text-align: left;
}
.middle .submit {
  margin: 20px 0px 0px 10px;
  text-align: center;
  padding-bottom: 10px;
}
.middle .submit .money {
  text-align: right;
  font-weight: 600;
  color: red;
  line-height: 25px;
}
</style>
