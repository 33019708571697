<template>
  <div id="page" ref="page" class="page">
    <van-row id="top">
      <van-col span="24">
        <van-nav-bar title="用户绑定查询" left-arrow @click-left="back()" />
      </van-col>
    </van-row>
    <van-row>
      <van-col span="24">
        <van-field
          v-model="branchText"
          is-link
          readonly
          label="分公司"
          placeholder="请选择分公司"
          @click="branchShow = true"
        />
        <van-popup v-model="branchShow" position="bottom">
          <van-picker
            show-toolbar
            title="标题"
            :columns="branchs"
            @confirm="onBranchConfirm"
            @cancel="branchShow = false"
          />
        </van-popup>
      </van-col>
    </van-row>
    <van-row class="middle">
      <van-col span="24">
        <van-row class="search">
          <van-col span="24">
            <van-search
              v-model="condition"
              show-action
              placeholder="请输入用户编码/身份证号/手机号码"
              @search="onSearch"
            >
              <template #action>
                <div @click="onSearch">搜索</div>
              </template>
            </van-search>
          </van-col>
        </van-row>

        <van-row class="subtitle">
          <van-col span="24">用户信息</van-col>
        </van-row>
        <van-row class="tips" v-if="houseList.length <= 0">
          <van-col span="24">
            <van-icon name="info-o" class="icon" />
            暂无记录，请输入查询条件进行查询
          </van-col>
        </van-row>
        <van-row v-for="house in houseList" :key="house.houseCode">
          <van-col span="24">
            <van-row class="card">
              <van-col span="24">
                <van-row class="info">
                  <van-col span="2" class="icon"
                    ><van-icon name="user-o"
                  /></van-col>
                  <van-col span="4" class="title">用户名称:</van-col>
                  <van-col span="18" class="content">{{ house.name }}</van-col>
                </van-row>
                <van-row class="info">
                  <van-col span="2"><van-icon name="idcard" /></van-col>
                  <van-col span="4">用户卡号:</van-col>
                  <van-col span="18">{{ house.number }}</van-col>
                </van-row>
                <van-row class="info">
                  <van-col span="2"><van-icon name="phone-o" /></van-col>
                  <van-col span="4">手机号码:</van-col>
                  <van-col span="18">{{ house.phone }}</van-col>
                </van-row>
                <van-row class="info">
                  <van-col span="2"><van-icon name="location-o" /></van-col>
                  <van-col span="4">用户地址:</van-col>
                  <van-col span="18">{{ house.address }}</van-col>
                </van-row>
                <van-row class="btn">
                  <van-col span="24"
                    ><van-button
                      type="primary"
                      size="mini"
                      @click="createBind(house.houseCode)"
                      >立即绑定用户</van-button
                    ></van-col
                  >
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row v-if="houseList.size <= 0">
          <van-col span="24"> </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-overlay :show="show">
      <div class="loading">
        <van-loading size="30px" color="#1989fa" vertical
          ><span class="text">加载中...</span></van-loading
        >
      </div>
    </van-overlay>
    <van-row class="bottom">
      <van-col span="24">
        <van-tabbar
          v-model="active"
          active-color="#07c160"
          inactive-color="#000"
        >
          <van-tabbar-item name="home" icon="home-o" @click="home()"
            >首页</van-tabbar-item
          >
          <van-tabbar-item name="bind" icon="friends-o" @click="bind()"
            >用户绑定</van-tabbar-item
          >
        </van-tabbar>
      </van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      branchText: '',
      branchShow: false,
      branchs: ['安庆分公司', '哈拉道口分公司', '兴隆洼分公司', '大庙分公司', '大西牛分公司', '木头沟分公司', '老府分公司', '西桥分公司', '金厂沟梁分公司'],
      condition: '',
      houseList: [],
      show: false,
      active: 'bind'
    }
  },
  mounted () {
  },
  methods: {
    onBranchConfirm (val) {
      this.branchText = val
      this.branchShow = false
    },
    async createBind (code) {
      var pd = { houseCode: code }
      const { data: res } = await this.$http.post('/business/house/createUserHouse', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog({
          title: '绑定',
          message: '绑定用户成功'
        }).then(() => {
          // on close
        })
      }
    },
    home () {
      this.$router.push('/')
    },
    bind () {
      this.$router.push('/bind')
    },
    back () {
      window.history.go(-1)
    },
    async onSearch () {
      this.show = true
      var pd = { condition: this.condition }
      const { data: res } = await this.$http.post('/business/house/retrieveHouseList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.houseList = res.data
      }
      this.show = false
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 90%;
  background-color: #fafafa;
}
.middle {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.tips {
  font-size: 14px;
  margin: 30px 0px 30px 0px;
  text-align: center;
}
.middle .subtitle {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 10px 0px 30px;
}
.middle .card {
  margin: 8px 10px 5px 10px;
  border-radius: 6px;
  background-color: #fff;
}
.middle .card .info {
  height: 20px;
  line-height: 20px;
  border-bottom: 1px solid #eee;
  padding: 5px;
  margin: 5px;
}
.middle .card .icon {
  font-size: 15px;
}
.middle .card .title {
  text-align: left;
}
.middle .card .btn {
  text-align: center;
  margin: 10px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loading .text {
  color: #1989fa;
}
</style>
