import Vue from 'vue'
import VueRouter from 'vue-router'
import Business from '../views/business/Index.vue'
import BusHome from '../views/business/Home.vue'
import BusPayment from '../views/business/Payment.vue'
import BusInvoice from '../views/business/Invoice.vue'
import BusBind from '../views/business/Bind.vue'
import BusResult from '../views/business/Result.vue'
import BusNotify from '../views/business/Notify.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'bus',
    component: Business,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'busHome',
        component: BusHome
      },
      {
        path: 'payment',
        name: 'busPayment',
        component: BusPayment
      },
      {
        path: 'invoice',
        name: 'busInvoice',
        component: BusInvoice
      },
      {
        path: 'bind',
        name: 'busBind',
        component: BusBind
      },
      {
        path: 'result',
        name: 'busResult',
        component: BusResult
      },
      {
        path: 'notify',
        name: 'busNotify',
        component: BusNotify
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
