<template>
  <div id="page" ref="page" class="page">
    <van-row id="top">
      <van-col span="24">
        <van-nav-bar title="费用明细" left-arrow @click-left="back()" />
      </van-col>
    </van-row>
    <van-row class="middle">
      <van-col span="24">
        <van-row class="subtitle">
          <van-col span="24">基础信息</van-col>
        </van-row>
        <van-row class="card">
          <van-col span="24">
            <van-row class="info">
              <van-col span="2" class="icon"
                ><van-icon name="user-o"
              /></van-col>
              <van-col span="4" class="title">用户名称:</van-col>
              <van-col span="18" class="content">{{ house.name }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="idcard" /></van-col>
              <van-col span="4">用户卡号:</van-col>
              <van-col span="18">{{ house.number }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="phone-o" /></van-col>
              <van-col span="4">手机号码:</van-col>
              <van-col span="18">{{ house.phone }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="location-o" /></van-col>
              <van-col span="4">用户地址:</van-col>
              <van-col span="18">{{ house.address }}</van-col>
            </van-row>
          </van-col>
        </van-row>
        <van-row class="subtitle">
          <van-col span="24">费用信息</van-col>
        </van-row>
        <van-row class="card">
          <van-col span="24">
            <van-row class="info">
              <van-col span="2" class="icon"
                ><van-icon name="clock-o"
              /></van-col>
              <van-col span="4" class="title">采暖年份:</van-col>
              <van-col span="18" class="content">{{ expense.years }}</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="ecard-pay" /></van-col>
              <van-col span="4">计费面积:</van-col>
              <van-col span="18">{{ expense.area }}㎡</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="cashier-o" /></van-col>
              <van-col span="4">欠费金额:</van-col>
              <van-col span="18">{{ expense.arrears }}￥</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="cash-back-record" /></van-col>
              <van-col span="4">折扣金额:</van-col>
              <van-col span="18">{{ expense.discount }}￥</van-col>
            </van-row>
            <van-row class="info">
              <van-col span="2"><van-icon name="after-sale" /></van-col>
              <van-col span="4">违约金额:</van-col>
              <van-col span="18">{{ expense.overdue }}￥</van-col>
            </van-row>
            <van-row class="submit">
              <van-col span="16" class="money"
                >实际付款：￥{{ paymentAmount }}元</van-col
              >
              <van-col span="8"
                ><van-button
                  color="linear-gradient(to right, #ff6034, #ee0a24)"
                  size="mini"
                  v-if="expense.agreePay === '是'"
                  @click="createPayment()"
                >
                  立即支付
                </van-button>
                <div v-if="expense.agreePay === '否'">不允许微信收费</div>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-overlay :show="show">
      <div class="loading">
        <van-loading size="30px" color="#1989fa" vertical
          ><span class="text">加载中...</span></van-loading
        >
      </div>
    </van-overlay>
    <van-tabbar v-model="active" active-color="#07c160" inactive-color="#000">
      <van-tabbar-item icon="home-o" @click="home()">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o" @click="syncReceived()"
        >用户绑定</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data () {
    return {
      houseCode: '',
      expenseCode: '',
      house: {},
      expense: {},
      paymentAmount: 0,
      show: true
    }
  },
  mounted () {
    var param = this.$route.query
    this.houseCode = param.houseCode
    this.expenseCode = param.expenseCode
    this.retrieveHouse()
    this.retrieveArrears()
    this.show = false
  },
  methods: {
    async retrieveHouse () {
      var pd = { houseCode: this.houseCode }
      const { data: res } = await this.$http.post('/business/house/retrieveHouse', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.house = res.data
      }
    },
    async retrieveArrears () {
      var pd = { expenseCode: this.expenseCode }
      const { data: res } = await this.$http.post('/business/payment/retrieveArrears', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.expense = res.data
        this.paymentAmount = res.data.arrears + res.data.overdue - res.data.discount
      }
    },
    async createPayment () {
      this.show = true
      var pd = {
        houseCode: this.houseCode,
        expenseCode: this.expenseCode,
        years: this.expense.years,
        received: this.paymentAmount,
        discount: this.expense.discount,
        overdue: this.expense.overdue
      }
      const { data: res } = await this.$http.post('/business/payment/createPayment', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.location.href = res.data
      }
      this.show = false
    },
    async syncReceived () {
      const { data: res } = await this.$http.post('/business/payment/createReceived')
      if (res.status === '200') {
        this.$router.push('/')
      }
    },
    home () {
      this.$router.push('/')
    },
    bind () {
      this.$router.push('/bind')
    },
    back () {
      window.history.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  background-color: #fafafa;
}
.middle {
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.middle .subtitle {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 10px 0px 30px;
}
.middle .card {
  margin: 8px 10px 10px 10px;
  border-radius: 6px;
  background-color: #fff;
}
.middle .card .info {
  height: 20px;
  line-height: 20px;
  border-bottom: 1px solid #eee;
  padding: 5px;
  margin: 5px;
}
.middle .card .icon {
  font-size: 15px;
}
.middle .card .title {
  text-align: left;
}
.middle .content {
  text-align: left;
}
.middle .submit {
  margin: 20px 0px 0px 10px;
  text-align: center;
  padding-bottom: 10px;
}
.middle .submit .money {
  text-align: right;
  font-weight: 600;
  color: red;
  line-height: 25px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading .text {
  color: #1989fa;
}
</style>
