import Vue from 'vue'
import {
  Button, NavBar, Tabbar, TabbarItem, Tabs, Tab, Tag, Icon, NoticeBar,
  Swipe, SwipeItem, SubmitBar, Lazyload, Search, Divider, Row, Col,
  Overlay, Loading,
  Grid, GridItem, Field, Popup, Picker
} from 'vant'

Vue.use(Button)
Vue.use(NavBar)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(SubmitBar)
Vue.use(Lazyload)
Vue.use(Search)
Vue.use(Divider)
Vue.use(Row)
Vue.use(Col)
Vue.use(Overlay)
Vue.use(Loading)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
