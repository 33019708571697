<template>
  <div></div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    // var query = this.$route.query
    this.createReceived('119H20210319192542776')
  },
  methods: {
    async createReceived (orderId) {
      var pd = { paymentCode: orderId }
      const { data: res } = await this.$http.post('/business/payment/createReceived', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.status)
      }
    }
  }
}
</script>
