<template>
  <div id="page" class="page">
    <remote-js
      src="https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js"
    ></remote-js>
    <van-row class="icon">
      <van-col span="24">
        <van-icon name="passed" />
      </van-col>
    </van-row>
    <van-row class="content">
      <van-col span="8" offset="1">商户名称:</van-col>
      <van-col span="12" class="right">{{ params.merName }}</van-col>
    </van-row>
    <van-row class="content">
      <van-col span="8" offset="1">订单编号:</van-col>
      <van-col span="12" class="right">{{ params.merOrderId }}</van-col>
    </van-row>
    <van-row class="content">
      <van-col span="8" offset="1">缴费金额:</van-col>
      <van-col span="12" class="right">{{ params.totalAmount }}元</van-col>
    </van-row>
    <van-row class="content">
      <van-col span="8" offset="1">缴费日期:</van-col>
      <van-col span="12" class="right">{{ params.payTime }}</van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  components: {
    'remote-js': {
      render (createElement) {
        return createElement('script', { attrs: { type: 'text/javascript', src: this.src } })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  data () {
    return {
      params: {}
    }
  },
  mounted () {
    var query = this.$route.query
    if (query !== undefined) {
      if (query.totalAmount !== undefined) {
        query.totalAmount = (query.totalAmount / 100).toFixed(2)
      }
      this.params = query
    }
    // const s = document.createElement('script')
    // s.type = 'text/javascript'
    // s.src = 'https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js'
    // document.body.appendChild(s)
    // this.createReceived()
    const mchData = { action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE' }
    const postData = JSON.stringify(mchData)
    window.parent.postMessage(postData, 'https://payapp.weixin.qq.com')
  },
  methods: {
    async createReceived () {
      var pd = { paymentCode: this.params.merOrderId }
      const { data: res } = await this.$http.post('/business/payment/createReceived', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.status)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  padding-top: 50px;
}
.icon {
  font-size: 50px;
  color: #2ca904;
}
.content {
  font-size: 14px;
  line-height: 30px;
}
.content .right {
  text-align: left;
}
</style>
