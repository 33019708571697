<template>
  <div id="page" ref="page" class="page">
    <van-row class="top">
      <van-col span="24">
        <van-nav-bar title="天宫热力微信营业厅" left-arrow />
        <van-swipe :autoplay="3000" class="swipe">
          <van-swipe-item :key="1">
            <img src="../../assets/images/banner.jpg" class="image" />
          </van-swipe-item>
        </van-swipe>
        <van-notice-bar
          class="notice"
          left-icon="volume-o"
          text="天宫热力微信营业厅系统已开通线上缴费功能，如有疑问请到线下收费点咨询"
        />
      </van-col>
    </van-row>
    <van-row class="middle">
      <van-col span="24">
        <van-tabs @click="tabClick">
          <van-tab title="待缴费用">
            <van-row class="tips" v-if="arrearsList.length <= 0">
              <van-col span="24">
                <van-icon name="info-o" class="icon" /> 暂无欠费记录
              </van-col>
            </van-row>
            <van-row
              class="record"
              v-for="arrears in arrearsList"
              :key="arrears.expenseCode"
            >
              <van-col span="24">
                <van-row class="head">
                  <van-col span="2" class="icon"
                    ><van-icon name="wap-home-o" size="20px"
                  /></van-col>
                  <van-col span="22" class="address">
                    {{ arrears.address }}</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">名称卡号：</van-col>
                  <van-col span="18"
                    >{{ arrears.name }}（{{ arrears.number }}）</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">采暖期：</van-col>
                  <van-col span="10"
                    ><van-tag type="success">{{
                      arrears.years
                    }}</van-tag></van-col
                  >
                  <van-col span="8" class="btn"
                    ><van-button
                      size="mini"
                      @click="payment(arrears.houseCode, arrears.expenseCode)"
                      color="linear-gradient(to right, #ff6034, #ee0a24)"
                    >
                      立即缴费
                    </van-button></van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">欠费金额：</van-col>
                  <van-col span="18" class="money"
                    >{{
                      arrears.arrears + arrears.overdue - arrears.discount
                    }}￥（滞纳金:{{ arrears.overdue }};折扣:{{
                      arrears.discount
                    }}）</van-col
                  >
                </van-row>
              </van-col>
            </van-row>
          </van-tab>
          <van-tab title="缴费记录" @click="retrieveReceivedList()">
            <van-row class="tips" v-if="receivedList.length <= 0">
              <van-col span="24">
                <van-icon name="info-o" class="icon" /> 暂无缴费记录
              </van-col>
            </van-row>
            <van-row
              class="record"
              v-for="received in receivedList"
              :key="received.receivedCode"
            >
              <van-col span="24">
                <van-row class="head">
                  <van-col span="2" class="icon"
                    ><van-icon name="wap-home-o" size="20px"
                  /></van-col>
                  <van-col span="22" class="address">
                    {{ received.address }}</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">名称卡号：</van-col>
                  <van-col span="18"
                    >{{ received.name }}（{{ received.number }}）</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">缴费时间：</van-col>
                  <van-col span="18">{{ received.time }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="6" class="title">采暖期：</van-col>
                  <van-col span="10"
                    ><van-tag type="success">{{
                      received.years
                    }}</van-tag></van-col
                  >
                  <van-col span="8" class="btn">
                    <!-- <van-button
                      v-if="received.invoice === '否'"
                      size="mini"
                      color="linear-gradient(to right, #ff6034, #ee0a24)"
                      @click="
                        invoice(received.houseCode, received.receivedCode)
                      "
                    >
                      申请开票
                    </van-button>
                    <van-button
                      v-if="received.invoice === '是'"
                      size="mini"
                      color="linear-gradient(to right, #ff6034, #ee0a24)"
                      @click="downloadInvoice(received.download)"
                    >
                      发票下载
                    </van-button> -->
                  </van-col>
                </van-row>
                <van-row>
                  <van-col span="6" class="title">折扣金额：</van-col>
                  <van-col span="18">{{ received.discount }}￥</van-col>
                </van-row>
                <van-row>
                  <van-col span="6" class="title">缴费金额：</van-col>
                  <van-col span="18" class="money"
                    >{{ received.received }}￥</van-col
                  >
                </van-row>
              </van-col>
            </van-row>
          </van-tab>
          <van-tab title="绑定用户">
            <van-row class="tips" v-if="bindList.length <= 0">
              <van-col span="24">
                <van-icon name="info-o" class="icon" /> 暂无绑定用户
              </van-col>
            </van-row>
            <van-row
              class="record"
              v-for="bind in bindList"
              :key="bind.houseCode"
            >
              <van-col span="24">
                <van-row class="head">
                  <van-col span="2" class="icon"
                    ><van-icon name="wap-home-o" size="20px"
                  /></van-col>
                  <van-col span="22" class="address">
                    {{ bind.address }}</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">名称卡号：</van-col>
                  <van-col span="18"
                    >{{ bind.name }}（{{ bind.number }}）</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">用户类型：</van-col>
                  <van-col span="10"
                    ><van-tag
                      type="success"
                      v-if="bind.type === 'USER_TYPE_RESIDENT'"
                      >居民</van-tag
                    ><van-tag
                      type="success"
                      v-if="bind.type === 'USER_TYPE_ENTERPRICE'"
                      >商业</van-tag
                    ></van-col
                  >
                  <van-col span="8" class="btn"
                    ><van-button
                      size="mini"
                      color="linear-gradient(to right, #ff6034, #ee0a24)"
                      @click="deleteUserHouse(bind.houseCode)"
                    >
                      解除绑定
                    </van-button></van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="6" class="title">预留电话：</van-col>
                  <van-col span="18">18192588959</van-col>
                </van-row>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="24" class="bind">
                <van-button
                  icon="plus"
                  type="primary"
                  size="small"
                  @click="bind()"
                  >新增用户绑定</van-button
                >
              </van-col>
            </van-row>
          </van-tab>
        </van-tabs>
      </van-col>
    </van-row>
    <van-overlay :show="show">
      <div class="loading">
        <van-loading size="30px" color="#1989fa" vertical
          ><span class="text">加载中...</span></van-loading
        >
      </div>
    </van-overlay>
    <van-tabbar v-model="active" active-color="#07c160" inactive-color="#000">
      <van-tabbar-item name="home" icon="home-o" @click="home()"
        >首页</van-tabbar-item
      >
      <van-tabbar-item name="bind" icon="friends-o" @click="bind()"
        >用户绑定</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      images: [
        '../../../assets/images/banner.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg'
      ],
      bindList: [],
      arrearsList: [],
      receivedList: [],
      show: true,
      active: 'home'
    }
  },
  mounted () {
    var param = this.$route.query
    var code = param.code
    this.login(code)
  },

  methods: {
    async login (code) {
      var tkn = window.sessionStorage.getItem('token')
      if (tkn === null) {
        const { data: res } = await this.$http.post('/login?username=' + code)
        if (res.status === '200') {
          var token = res.data.token
          window.sessionStorage.setItem('token', token)
          this.syncReceived()
        }
      } else {
        this.syncReceived()
      }
      this.show = false
    },
    tabClick (name, title) {
      this.show = true
      if (title === '待缴费用') {
        this.retrieveArrearsList()
      } else if (title === '缴费记录') {
        this.retrieveReceivedList()
      } else if (title === '绑定用户') {
        this.retrieveBindList()
      }
      this.show = false
    },
    async retrieveBindList () {
      this.show = true
      const { data: res } = await this.$http.post('/business/house/retrieveUserHouseList')
      if (res.status === '200') {
        this.bindList = res.data
      }
      this.show = false
    },
    async retrieveArrearsList () {
      this.show = true
      const { data: res } = await this.$http.post('/business/payment/retrieveArrearsList')
      if (res.status === '200') {
        this.arrearsList = res.data
      }
      this.show = false
    },
    async retrieveReceivedList () {
      this.show = true
      const { data: res } = await this.$http.post('/business/payment/retrieveReceivedList')
      if (res.status === '200') {
        this.receivedList = res.data
      }
      this.show = false
    },
    deleteUserHouse (code) {
      this.$dialog.confirm({
        title: '绑定',
        message: '您确定删除绑定房产吗？'
      })
        .then(async () => {
          var pd = { houseCode: code }
          const { data: res } = await this.$http.post('/business/house/deleteUserHouse', this.$qs.stringify(pd))
          if (res.status === '200') {
            this.$dialog({
              title: '绑定',
              message: '删除绑定成功'
            }).then(() => {
              // on close
            })
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    async syncReceived () {
      const { data: res } = await this.$http.post('/business/payment/syncReceived')
      if (res.status === '200') {
        console.log(res.status)
      }
      this.retrieveArrearsList()
    },
    home () {
      this.$router.push('/')
    },
    bind () {
      this.$router.push('/bind')
    },
    payment (houseCode, expenseCode) {
      this.$router.push({ path: '/payment', query: { houseCode: houseCode, expenseCode: expenseCode } })
    },
    invoice (houseCode, receivedCode) {
      this.$router.push({ path: '/invoice', query: { houseCode: houseCode, receivedCode: receivedCode } })
    },
    downloadInvoice (url) {
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}
.swipe .image {
  width: 100%;
  height: 180px;
}
.notice {
  height: 30px;
}
.middle {
  width: 100%;
  margin: 0px 0px 60px 0px;
  background-color: #fafafa;
}
.tips {
  font-size: 14px;
  margin: 30px 0px 30px 0px;
}
.record {
  text-align: left;
  margin: 5px 5px 5px 5px;
  background: #fff;
  font-size: 14px;
  line-height: 25px;
  border-radius: 5px;
}
.record .head {
  border-bottom: 1px solid #eee;
}
.record .head .icon {
  text-align: center;
  line-height: 15px;
  padding-top: 5px;
}
.record .head .address {
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
}

.record .title {
  text-align: right;
}
.record .btn {
  text-align: center;
}
.record .money {
  color: red;
  font-weight: 600;
}
.bind {
  margin: 10px 0px 80px 0px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading .text {
  color: #1989fa;
}
</style>
